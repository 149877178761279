  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-2-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>Expertise fissures</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Expertise fissure Aix-en-Provence</h1>
<p>L’expertise fissure fait intervenir un expert construction dont l’analyse des pathologies affectant un ouvrage (maison, immeuble, mur etc..) va permettre de déterminer les causes et origines des fissures, appelés désordres, afin d’orienter le client sur les solutions techniques réparatoires les plus adaptées.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expertise fissures" />
</div>

<h2>Différencier fissures “esthétiques” de fissures structurelles</h2>

<p>La fissuration des ouvrages est une pathologie courante sur les ouvrages d’habitations individuelles. </p>
<p>Les fissures peuvent avoir différentes formes, ouvertures, directions mais témoignent toujours de mouvements s’exerçant dans la structure.</p>

<p>On distingue :</p>
<ul><li>Microfissure : la microfissure présente une ouverture linéaire inférieure à 0,2 mm,</li>
<li>Fissure : la fissure se définit par une ouverture de 0,2 à 2 mm,</li>
<li>Lézarde : Le terme de “Lézarde” ou Crevasse s'applique à une ouverture supérieure à 2 mm.</li></ul>

<p>Savoir si une fissure est grave, si elle est évolutive et peut affecter à terme la solidité structurelle d’un ouvrage n’est pas chose aisée pour l’oeil d’un profane. C’est pourquoi l’avis technique d’un expert construction peut s’avérer parfois nécéssaire afin d’être bien orienté sur les travaux de reprise à effectuer.</p>

<h2>Les causes d’apparition de fissures sur un ouvrage</h2>

<p>Les causes et origines des fissures peuvent être multiples, d’ordre structurel et/ou provoquer par un agent naturel externe : </p>

<ul><li>tassement des fondations du au retrait/gonflement d’un sol argileux</li>
<li>dilatation des matériaux,</li>
<li>mauvaise conception et réalisation lors des travaux,</li>
<li>mauvais dimensionnement,</li>
<li>surcharges,</li></ul>

<p>Si vous avez un doute, une inquiétude concernant des fissures sur votre ou que vous  constatez leur évolution, les conseils avisés d’un Expert construction spécialistes en fissures peut s’avérer être utile.</p>

<h2>Nos prestations “expertise fissure”</h2>

<p>Le cabinet RV EXPERTISES accompagne les assurés, particuliers et entreprises dans leur démarche d’assistance technique et de conseil construction.</p>

<p>Nous intervenons :</p>
<ul><li>dans le cadre d’une expertise unilatérale : besoin d’un avis technique sur la gravité de fissures constatées.</li>
<li>dans le cadre d’une expertise d’assuré : accompagnement de l’assuré pour un sinistre consécutif à un épisode de sécheresse et de retrait/gonflement des argiles,</li></ul>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Nous contacter</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expertise-fissures/' className='active'>Expertise fissures</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details